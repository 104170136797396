import React from 'react';
import { GlobalStyle } from '../theme/global-style';
import '../theme/fonts/fonts.css';

export const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
);
