import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Layout } from './src/components/layout';
import { theme } from './src/theme/theme';

export const rootWrapper = ({ element }) => (
  <ThemeProvider theme={theme}>
    <Layout>{element}</Layout>
  </ThemeProvider>
);
