export const breakpoint = {
  xs: 375,
  sm: 425,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1600,
  max: 1900,
};

export const screen = {
  xs: {
    up: `(min-width: ${breakpoint.xs}px)`,
    down: `(max-width: ${breakpoint.sm - 0.02}px)`,
  },
  sm: {
    up: `(min-width: ${breakpoint.sm}px)`,
    down: `(max-width: ${breakpoint.md - 0.02}px)`,
  },
  md: {
    up: `(min-width: ${breakpoint.md}px)`,
    down: `(max-width: ${breakpoint.lg - 0.02}px)`,
  },
  lg: {
    up: `(min-width: ${breakpoint.lg}px)`,
    down: `(max-width: ${breakpoint.xl - 0.02}px)`,
  },
  xl: {
    up: `(min-width: ${breakpoint.xl}px)`,
    down: `(max-width: ${breakpoint.xxl - 0.02}px)`,
  },
  xxl: {
    up: `(min-width: ${breakpoint.xxl}px)`,
    down: `(max-width: ${breakpoint.xxxl - 0.02}px)`,
  },
  xxxl: {
    up: `(min-width: ${breakpoint.xxxl}px)`,
    down: `(max-width: ${breakpoint.max - 0.02}px)`,
  },
  max: {
    up: `(min-width: ${breakpoint.max}px)`,
  },
};
